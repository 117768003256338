import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';

import useIsDocumentReady from '@core/hooks/useIsDocumentReady';
import useIsElementVisible from '@core/hooks/useIsElementVisible';
import useMeta from '@core/hooks/useMeta';
import classy from '@core/utils/classy';

import Modal from '@ui/Modal';

import Destination, { destinationPropTypes } from './components/Destination';
import classes from './style.module.scss';

const QuickNav = ({ destinations, modalTarget }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [isInTabOrder, setIsInTabOrder] = useState(false);
  const [selected, setSelected] = useState(0);
  const [shortcut, setShortcut] = useState('');
  const inputRef = useRef();
  const quickNavModalRef = useRef();
  const modalContainerRef = useRef();

  const { setElement, visible } = useIsElementVisible();
  const isDocumentReady = useIsDocumentReady();

  const meta = useMeta('/');

  // After initial render, use shortcut that is dependent on client being load
  // Note: this prevents server/client hydration warning
  useEffect(() => {
    setShortcut(meta);
  }, [meta]);

  useEffect(() => {
    const modalTargetEl = document.querySelector(modalTarget);
    setElement(modalTargetEl);
  }, [isDocumentReady, modalTarget, setElement]);

  useEffect(() => {
    if (isOpen === true) {
      setFilter('');
      setSelected(0);
      if (inputRef.current) inputRef.current.value = '';
    }
    quickNavModalRef.current?.toggle(isOpen);
    setIsInTabOrder(isOpen);
  }, [inputRef, isOpen, quickNavModalRef]);

  useEffect(() => {
    if (isInTabOrder && isOpen) inputRef?.current?.focus();
  }, [isInTabOrder, isOpen]);

  const handleOutsideClick = useCallback(
    e => {
      if (!modalContainerRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    },
    [modalContainerRef],
  );

  useEffect(() => {
    if (visible) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [handleOutsideClick, visible]);

  const handleKeydown = useCallback(
    e => {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
      if (e.key === '/' && (e.ctrlKey || e.metaKey)) {
        const isModalOpen = quickNavModalRef.current?.state.open;
        setIsOpen(!isModalOpen);
      }
    },
    [quickNavModalRef],
  );

  useEffect(() => {
    if (visible) {
      document.addEventListener('keydown', handleKeydown);
    } else {
      document.removeEventListener('keydown', handleKeydown);
    }
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown, visible]);

  const filteredData = destinations?.reduce((filteredSectionAccumulator, category) => {
    const filteredCategory = { ...category };
    filteredCategory.children = category.children.filter(
      page =>
        page.title.toLowerCase().includes(filter.toLowerCase()) ||
        page.method?.toLowerCase().includes(filter.toLowerCase()),
    );
    if (!filteredCategory.children.length) return filteredSectionAccumulator;
    return filteredSectionAccumulator.concat(filteredCategory);
  }, []);

  const endpointsRef = useMemo(() => [], []);

  useEffect(() => {
    const endpointsList = [];
    endpointsRef?.forEach(item => {
      if (item?.children) endpointsList.push(...item.children);
    });

    if (endpointsList.length > 0 && selected < endpointsList.length && selected > 0) {
      if (endpointsList[selected].children[0]) endpointsList[selected].children[0].focus();
    } else setSelected(0);

    if (selected === 0) inputRef.current?.focus();
  }, [endpointsRef, selected]);

  const modalNav = e => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault();
      const direction = e.key === 'ArrowDown' ? 1 : -1;
      setSelected(selected + direction);
    }
  };

  return (
    <>
      {!!destinations && (
        <button
          aria-keyshortcuts="Control+/ Meta+/"
          className={classy(classes.QuickNav, classes['QuickNav-button'])}
          onClick={() => setIsOpen(true)}
        >
          JUMP TO
          {!!shortcut && <span className={classes['QuickNav-key']}>{shortcut}</span>}
        </button>
      )}
      <Modal
        ref={quickNavModalRef}
        className={classy(classes.QuickNav, classes['QuickNav-modal'])}
        onClose={() => setIsOpen(false)}
        onKeyDown={e => modalNav(e)}
        scrollable
        sectionRef={modalContainerRef}
        target={modalTarget}
      >
        <input
          ref={inputRef}
          className={classy('Input', 'Input_md', classes['QuickNav-modal-search'])}
          onChange={e => {
            setSelected(0);
            setFilter(e.target.value);
          }}
          placeholder="Filter"
          tabIndex={isInTabOrder ? 0 : -1}
          type="search"
        />
        <nav aria-label="Reference navigation" className={classes['QuickNav-content']} role="navigation">
          {filteredData?.map((category, i) => (
            <ol
              key={`${category.id}-ol`}
              ref={list => {
                endpointsRef[i] = list;
              }}
              className={classes['QuickNav-destination-section']}
            >
              <li key={category.id} className={classes['QuickNav-destination-section-heading']}>
                {category.title}
              </li>

              {category.children.map(destination => (
                <Destination
                  key={`destination-${destination.id}`}
                  destination={destination}
                  isInTabOrder={isInTabOrder}
                  setIsOpen={setIsOpen}
                />
              ))}
            </ol>
          ))}
        </nav>
      </Modal>
    </>
  );
};

const childrenPropTypes = destinationPropTypes.destination;

QuickNav.propTypes = {
  /**
   * Array of categories and child destination objects to be navigated and filtered (see: <Destination />)
   */
  destinations: PropTypes.arrayOf(childrenPropTypes),
  /**
   * a string for the id of the HTML element that will be passed to <Modal /> for designating a portal root
   */
  modalTarget: PropTypes.string,
};

export default QuickNav;
