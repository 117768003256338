import type { ForwardedRef } from 'react';

import { capitalize } from '@readme/iso';
import Tippy from '@tippyjs/react';
import React, { useCallback, useRef, useState, forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import type { DateRange as DateRangeType, DateRangeOptions, MetricsFilters } from '@core/types/metrics';
import { hideOnEsc } from '@core/utils/tippy';

import Button from '@ui/Button';
import ButtonGroup from '@ui/ButtonGroup';
import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import CustomRangeTooltip from './CustomRangeTooltip';
import classes from './style.module.scss';

interface DateRangeProps {
  children?: React.ReactChildren;
  dateRanges: DateRangeOptions;
  filters: MetricsFilters;
  onCustomRangeChange: (key: string, range: unknown) => void;
  onCustomRangeClick: () => void;
  onRangeClick: (key: string, range: DateRangeType) => void;
  selectedKey?: string;
}

export const dateRangeDefaults = {
  day: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 1,
    resolution: 'day',
    enabled: true,
  },
  week: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 7,
    resolution: 'day',
    enabled: true,
  },
  month: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 30,
    resolution: 'day',
    enabled: true,
  },
  quarter: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 84,
    resolution: 'day',
    enabled: true,
  },
  year: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 365,
    resolution: 'day',
    enabled: true,
  },
} as DateRangeOptions;

const DateRange = forwardRef<HTMLDivElement, DateRangeProps>(
  (
    { selectedKey, onRangeClick, dateRanges, onCustomRangeClick, filters, onCustomRangeChange }: DateRangeProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const bem = useClassy(classes, 'DateRange');
    const [showCustomRangeTooltip, setShowCustomRangeTooltip] = useState(false);
    // Whether all date range options are enabled
    const allDateRangesEnabled = Object.values(dateRanges as DateRangeOptions).every(option => option.enabled);
    const customRangeRef = useRef(null);

    const handleCustomRangeChange = useCallback(
      (key, range) => {
        setShowCustomRangeTooltip(false);

        onCustomRangeChange(key, range);
      },
      [onCustomRangeChange],
    );

    return (
      <div className={bem('&')}>
        <div ref={ref} className={bem('-btn-group')}>
          <ButtonGroup highlightSelection={allDateRangesEnabled} selectedKey={selectedKey}>
            {[
              ...Object.entries(dateRanges as DateRangeOptions).map(([key, range]) => (
                <Button
                  key={key}
                  className={bem(
                    '&',
                    !range.enabled && '-disabled-btn',
                    !range.enabled && selectedKey === key && '-active-disabled-btn',
                  )}
                  kind="secondary"
                  onClick={() => {
                    if (range.enabled) {
                      onRangeClick(key, range);
                    }
                  }}
                  outline
                  size="sm"
                >
                  {capitalize(key)}
                </Button>
              )),
              <Button
                key="custom"
                ref={customRangeRef}
                aria-label="Custom Date"
                className={bem('&', !allDateRangesEnabled && '-disabled-btn')}
                data-testid="custom-range-btn"
                kind="secondary"
                onClick={() => {
                  if (allDateRangesEnabled) {
                    onCustomRangeClick();
                    setShowCustomRangeTooltip(true);
                  }
                }}
                outline
                size="sm"
              >
                <Tooltip content="Custom range">
                  <Icon aria-hidden="true" name="calendar" />
                </Tooltip>
              </Button>,
            ]}
          </ButtonGroup>
        </div>

        {/* Custom date range tooltip */}
        <Tippy
          appendTo={() => document.body}
          arrow={false}
          content={
            <CustomRangeTooltip
              activeFilters={filters}
              onApply={handleCustomRangeChange}
              onCancel={() => setShowCustomRangeTooltip(false)}
            />
          }
          interactive
          maxWidth={200}
          onClickOutside={() => {
            setShowCustomRangeTooltip(false);
          }}
          placement="bottom-end"
          plugins={[hideOnEsc]}
          reference={customRangeRef}
          visible={showCustomRangeTooltip}
        />
      </div>
    );
  },
);

DateRange.displayName = 'DateRange';

export default DateRange;
