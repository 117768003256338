import type { APISettingSchema } from '@readme/backend/models/apisetting/types';
import type { ColumnDef, RowData } from '@tanstack/react-table';

import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import useClassy from '@core/hooks/useClassy';

import SectionHeader from '@ui/API/SectionHeader';
import Box from '@ui/Box';
import Flex from '@ui/Flex';
import ReactTable from '@ui/ReactTable';
import { TextCell } from '@ui/ReactTable/components/Cells';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

interface Props {
  error: boolean;
  info: APISettingSchema['info'][];
  isLoading: boolean;
}

const InfoTable = ({ info, error, isLoading }: Props) => {
  const bem = useClassy(classes, 'InfoTable');

  const columnHelper = createColumnHelper<APISettingSchema['info']>();

  const infoColumns = [
    columnHelper.accessor('title', {
      header: 'Title',
      minSize: 140, // at least enough so our APIs don’t get cut off :)
    }),
    columnHelper.accessor('summary', {
      header: 'Summary',
    }),
    columnHelper.accessor('description', {
      header: 'Description',
      minSize: 400,
    }),
    columnHelper.accessor('termsOfService', {
      header: 'Terms of Service',
      minSize: 120, // long enough so that text isn’t cut off
    }),
    columnHelper.accessor('contact', {
      header: 'Contact',
      minSize: 200,
      cell: ({ getValue }) => {
        const contact = getValue();

        return contact ? (
          <Tooltip
            asTitle
            content={
              <Flex gap="xs" layout="col">
                <b>{contact.name}</b>
                <div>{contact.email}</div>
                <div>{contact.url}</div>
              </Flex>
            }
          >
            <div>
              {!!contact?.name && <TextCell showTooltip={false} value={contact.name} />} •{' '}
              {!!contact?.email && <TextCell showTooltip={false} value={contact.email} />} •{' '}
              {!!contact?.url && <TextCell showTooltip={false} value={contact.url} />} •{' '}
            </div>
          </Tooltip>
        ) : (
          <span>-</span>
        );
      },
    }),
    columnHelper.accessor('license', {
      header: 'License',
      minSize: 120,
      cell: ({ getValue }) => {
        const license = getValue();

        return license ? (
          <Flex gap={0} layout="col">
            {!!license?.name && <TextCell tooltipValue={`Name: ${license.name}`} value={license.name} />}
            {!!license?.identifier && (
              <TextCell tooltipValue={`Identifier: ${license.identifier}`} value={license.identifier} />
            )}
            {!!license?.url && <TextCell tooltipValue={`URL: ${license.url}`} value={license.url} />}
          </Flex>
        ) : (
          <span>-</span>
        );
      },
    }),
    columnHelper.accessor('version', {
      header: 'Version',
    }),
  ];

  return (
    <section className={bem('&')}>
      <Flex align="center">
        <SectionHeader className={bem('-header')} heading="API Info" />
      </Flex>
      <Box className={bem('-box-wrapper')} kind="card">
        <ReactTable
          className={bem('-table')}
          columns={infoColumns as ColumnDef<RowData>[]}
          data={info}
          hasError={!!error}
          hidePagination
          isLoading={isLoading}
          onPageChange={() => {}} // todo: make optional for tables with no pagination
          onSort={() => {}} // todo: make optional for tables with no sorting
          page={0}
          pages={0}
          sort={{
            column: 'firstName',
            direction: 'desc',
          }}
          transparentHeader
        />
      </Box>
    </section>
  );
};

export default InfoTable;
