import type { $TSFixMe, HTTPMethod } from '@readme/iso';
import type { Header, Param, Request, Response } from 'har-format';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';
import type { HeaderObject, HttpMethods, SchemaObject } from 'oas/types';

import { getStatusCode, getStatusCodeMessage } from '@readme/http-status-codes';
import React, { useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ThemeContext } from '@Hub/Theme';

import useDereference from '@core/hooks/useDereference';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useFormatResponseLogCode from '@core/hooks/useFormatResponseLogCode';
import useMediaQuery from '@core/hooks/useMediaQuery';
import { useReferenceStore } from '@core/store';

import Method from '@ui/API/Method';
import APIResponseHeadersModal from '@ui/API/ResponseHeadersModal';
import Badge from '@ui/Badge';
import Button from '@ui/Button';
import type { CodeSnippetProps } from '@ui/CodeSnippet';
import CodeSnippet from '@ui/CodeSnippet';
import CopyToClipboard from '@ui/CopyToClipboard';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import Modal from '@ui/Modal';

import classes from './style.module.scss';
import './style.scss';

interface APIResponseProps {
  /** An Oas class instance */
  apiDefinition: Oas;

  /** Determines if we automatically expand the 200 response example or not */
  defaultExpandResponseExample?: boolean;

  /** An Executor-returned or Metrics HAR to render instead of a response example. */
  har?: $TSFixMe;

  /** Triggered when a menu example or passed in HAR is cleared out. */
  onExampleRemove?: () => void;

  /** Triggered when a menu example is selected. */
  onExampleSelect?: (example: unknown) => void;

  /** For example `apiDefinition.operation('/pets', 'get')`. */
  operation: Operation;

  /** Options passed to CodeMirror */
  options?: CodeSnippetProps['options'];

  /**
   * Does the API definition allow making requests?
   * @see {@link https://docs.readme.com/main/docs/openapi-extensions#disable-the-api-explorer}
   */
  requestsEnabled?: boolean;

  /** State to control the selected response log */
  setSelectedHar?: (har: $TSFixMe) => void;
}

function isEmptyCodeExample(code) {
  return !code || code === '""';
}

type CallbackExample = Operation['callbackExamples'][number];

export type ResponseExample = Operation['responseExamples'][number];

interface AugmentedResponseExample {
  id: string;
  mediaType: string | null;
  responseExamples: CompiledResponseExamples;
  status: string;
}

type CompiledResponseExamples = ReturnType<typeof compileResponseExamples>;

export type ExtendedMediaTypeExample = ResponseExample['mediaTypes'][string][number] & {
  code: $TSFixMe;
  mediaType: string;
};

function compileResponseExamples(
  acc: Map<string, Map<string, ExtendedMediaTypeExample>>,
  response: Operation['responseExamples'][number],
) {
  Object.entries(response.mediaTypes).forEach(([mediaType, mediaTypeExamples]) => {
    const examples = new Map<string, ExtendedMediaTypeExample>();

    mediaTypeExamples.forEach(example => {
      if (isEmptyCodeExample(example.value)) {
        return;
      }

      // Ensure that each have a unique key within the map by concatenating their HTTP status with
      // their `summary` (if present -- we're using `filter(Boolean)` because `summary` is optional
      // on examples within the OpenAPI spec).
      const uniqKey = [response.status, example.summary].filter(Boolean).join('_');
      examples.set(uniqKey, {
        ...example,
        mediaType,
        code: example.value as string,
      });
    });

    // Generate an example of the status message so we can display render response examples that
    // **only** have header examples
    if (response.onlyHeaders && !mediaTypeExamples.length && mediaType === '*/*') {
      const uniqKey = response.status;
      const example: ExtendedMediaTypeExample = {
        code: getStatusCodeMessage(response.status),
        value: undefined,
        mediaType,
      };

      acc.set(mediaType, new Map([[uniqKey, example]]));
    }

    // Because we're keying our map off of the media type, but examples are processed by HTTP code
    // we don't want to overwrite already coolated examples for a previous HTTP code when
    // processing the one after it (and the one after it, etc.).
    if (!acc.has(mediaType)) {
      acc.set(mediaType, examples);
    } else {
      acc.set(
        mediaType,
        new Map([
          ...Array.from((acc as Map<string, Map<string, ExtendedMediaTypeExample>>).get(mediaType) || []),
          ...Array.from(examples),
        ]),
      );
    }
  });

  return acc;
}

const compileCallbackExamples = (
  acc: Map<string, Map<string, ExtendedMediaTypeExample>>,
  callback: CallbackExample,
  operation: Operation,
) => {
  const callbackOperation = operation.getCallback(
    callback.identifier,
    callback.expression,
    callback.method as HttpMethods,
  );

  (typeof callback.example === 'object' && Array.isArray(callback.example) ? callback.example : []).forEach(ex => {
    Object.entries(ex.mediaTypes).forEach(([mediaType, mediaTypeExamples]) => {
      const examples = new Map<string, ExtendedMediaTypeExample>();

      mediaTypeExamples.forEach(example => {
        if (isEmptyCodeExample(example.value)) {
          return;
        }

        // Ensure that each have a unique key within the map by concatenating their HTTP status
        // with their identifier/operationID, `method` and `summary` (if present -- we're using
        // `filter(Boolean)` because `summary` is optional on callback examples within the OpenAPI
        // spec).
        const uniqKey = [
          // Check if there's an `operationId` since we default to that over the identifier for the
          // visible label.
          callbackOperation && callbackOperation.hasOperationId()
            ? callbackOperation.getOperationId()
            : callback.identifier,
          callback.method,
          example.summary,
        ]
          .filter(Boolean)
          .join('_');

        examples.set(uniqKey, {
          ...example,
          mediaType,
          code: example.value,
        });
      });

      // Because we're keying our map off of the media type, but examples are processed by HTTP
      // code we don't want to overwrite already coolated examples for a previous HTTP code when
      // processing the one after it (and the one after it, etc.).
      if (!acc.has(mediaType)) {
        acc.set(mediaType, examples);
      } else {
        acc.set(mediaType, new Map([...Array.from(acc.get(mediaType) || []), ...Array.from(examples)]));
      }
    });
  });

  return acc;
};

const APIResponse: React.FC<APIResponseProps> = ({
  defaultExpandResponseExample,
  har,
  operation,
  onExampleRemove,
  onExampleSelect,
  requestsEnabled,
  setSelectedHar,
  ...props
}) => {
  const history = useHistory();

  const { isDereferenced } = useDereference(props.apiDefinition);
  const [selectedExampleType, setSelectedExampleType] = useState<'Callback' | 'Response'>('Response');
  const [responseHeaders, setResponseHeaders] = useState<Header[] | null>(null);
  const [requestHeaders, setRequestHeaders] = useState<Header[] | null>(null);
  const [requestData, setRequestDataHeaders] = useState<Param[] | null>(null);

  const [languageLibrary] = useReferenceStore(store => [store.language.languageLibrary]);
  const correspondingCustomCodeExample = languageLibrary?.snippet?.correspondingExample;

  // Generates a key-value pair of HTTP status codes -> media types -> response examples.
  const responseExamples = useMemo(
    () => {
      if (!isDereferenced) return new Map<string, Map<string, ExtendedMediaTypeExample>>();

      const examples =
        selectedExampleType === 'Callback' && operation.getCallbackExamples()
          ? operation.getCallbackExamples()
          : operation.getResponseExamples();

      // Iterate through all HTTP codes that this operation responds with and flatten them out into
      // a map of HTTP codes -> media types -> examples.
      const res = examples.reduce((acc, response) => {
        if (selectedExampleType === 'Response') {
          return compileResponseExamples(acc, response as ResponseExample);
        }

        return compileCallbackExamples(acc, response as CallbackExample, operation);
      }, new Map<string, Map<string, ExtendedMediaTypeExample>>());

      // Filter out any empty media type example maps.
      res.forEach((ex, mediaType) => {
        if ('size' in ex && !ex.size) {
          res.delete(mediaType);
        }
      });

      return res;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Exhaustive hooks rule wants `operation` here.
    [isDereferenced, operation.path, operation.method, selectedExampleType],
  );

  const responseHeadersModal = useRef<Modal>(null);

  const isExamplesPopulated = Boolean(responseExamples.size);

  const fallbackLanguage = 'application/json' as const;

  const timestamp = har?.log.entries[0].startedDateTime;

  const hasProvidedData = har || false;
  const [providedCode, providedStatus, providedLanguage, providedHeaders] = useMemo(() => {
    const response: Response | undefined = har?.log.entries[0].response;
    const request: Request | undefined = har?.log.entries[0].request;

    let code = '';

    // A valid boolean response will fail the first check here so we need to also check the type
    // so we'll be check the text type so it will display
    if (response?.content.text || typeof response?.content.text === 'boolean') {
      code = response?.content.text;
    } else if (response?.statusText) {
      code = response?.statusText;
    } else if (response?.status) {
      try {
        code = getStatusCode(response?.status).message;
      } catch {
        // no-op
      }
    }

    const status = response?.status || null;
    const headers = response?.headers || null;
    const requestBodyHeaders = request?.headers || null;

    let requestBodyValue: string;
    try {
      requestBodyValue = JSON.parse(JSON.stringify(request?.postData?.text));
    } catch (e) {
      requestBodyValue = '';
    }

    const requestDataValue: Param[] | null = request?.postData
      ? request.postData.mimeType === 'application/json'
        ? [{ name: 'body', value: requestBodyValue }]
        : request.postData.params || null
      : null;

    setResponseHeaders(headers);
    setRequestHeaders(requestBodyHeaders);
    setRequestDataHeaders(requestDataValue);

    // We're supplying `fallbackLanguage` here because we don't yet support surfacing any other
    // mime types from the provided HAR.
    return [code, status, fallbackLanguage, headers, requestHeaders, requestDataValue];
  }, [JSON.stringify(har)]); // eslint-disable-line react-hooks/exhaustive-deps

  const [code, setCode] = useState(providedCode);
  const [selectedExample, setSelectedExample] = useState<Partial<AugmentedResponseExample> | null>(null);
  const [expandedState, setExpandedState] = useState(true);
  const { formattedCode, language, setFormattedLanguage } = useFormatResponseLogCode(code, providedLanguage);

  /**
   * These editor options do the following:
   *    - allow code folding, which defaults to edit mode, so we have to also specify readOnly mode.
   *    - disable codemirror's default tab-trap behavior, see RM-929
   */
  const { isClient } = useEnvInfo();
  const { colorMode } = useContext(ThemeContext);
  const userColorScheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
  const initialStoredTheme = isClient ? localStorage.getItem('color-scheme') : null;
  const colorScheme =
    colorMode === 'system' && initialStoredTheme === 'system' ? userColorScheme : initialStoredTheme || colorMode;

  useEffect(() => {}, [userColorScheme]);

  const options = useMemo<CodeSnippetProps['options']>(
    () => ({
      customTheme: colorScheme === 'light' ? 'neo' : 'material-palenight',
      foldGutter: true,
      readOnly: true,
      extraKeys: { Tab: false, 'Shift-Tab': false },
      ...props.options,
    }),
    [props.options, colorScheme],
  );

  useEffect(() => {
    // Clear out any selected response example or log.
    setSelectedExample(null);
  }, [
    // `responseExamples` is listed as a dependency because that will only change if the operation
    // changes (i.e. when the user navigates to a different endpoint) and we want to clear out the
    // selected example in these cases.
    responseExamples,

    // `timestamp` is listed as a dependency because we want to re-render the code snippet every
    // time an API request is made; even if the API response code doesn't change we still need to
    // trigger a re-render in order to overwrite any fallback code or code exampels that may be
    // currently rendered.
    timestamp,
  ]);

  const toggleSelectedExample = useCallback(
    (mediaType: string | null, status?: string, id?: string) => {
      setSelectedExample({ mediaType, status, id, responseExamples });
      if (mediaType === null) {
        if (onExampleSelect) {
          onExampleSelect(null);
        }
        if (onExampleRemove) {
          onExampleRemove();
        }
        if (setSelectedHar) {
          setSelectedHar(null);
        }

        return;
      }

      if (onExampleSelect && responseExamples.has(mediaType)) {
        onExampleSelect((responseExamples.get(mediaType) as Map<string, ExtendedMediaTypeExample>).get(id || ''));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [responseExamples, onExampleRemove, onExampleSelect],
  );

  const handleMenuItemClick = useCallback(
    (mediaType: string | null, status?: string, id?: string) => {
      toggleSelectedExample(mediaType, status, id);

      if (mediaType === null && onExampleRemove) {
        history.push('?');
      }
    },
    [history, onExampleRemove, toggleSelectedExample],
  );

  useEffect(() => {
    setExpandedState(true);
  }, [operation]);

  useEffect(() => {
    if (defaultExpandResponseExample && !correspondingCustomCodeExample) {
      if (responseExamples?.size) {
        [...responseExamples].some(([mediaType, mediaTypeExamples]) => {
          return [...mediaTypeExamples].some(([key]) => {
            const [statusCode] = key.split('_');
            if (statusCode.startsWith('2') && expandedState) {
              setExpandedState(false);
              toggleSelectedExample(mediaType, statusCode, key);
              return true;
            }
            return false;
          });
        });
      }
    }

    if (correspondingCustomCodeExample && !hasProvidedData) {
      if (responseExamples?.size) {
        [...responseExamples].some(([mediaType, mediaTypeExamples]) => {
          return [...mediaTypeExamples].some(([key, value]) => {
            if (correspondingCustomCodeExample === value.title) {
              const [statusCode] = key.split('_');
              toggleSelectedExample(mediaType, statusCode, key);
              return true;
            }
            return false;
          });
        });
      }
    } else if (!hasProvidedData && !defaultExpandResponseExample) {
      toggleSelectedExample(null);
    }
  }, [
    correspondingCustomCodeExample,
    defaultExpandResponseExample,
    expandedState,
    toggleSelectedExample,
    hasProvidedData,
    languageLibrary,
    operation,
    responseExamples,
  ]);

  const toggleResponseHeadersModal = useCallback(() => {
    if (responseHeadersModal.current) responseHeadersModal.current.toggle();
  }, []);

  // Updates the rendered code to match the selected example.
  useEffect(() => {
    if (
      selectedExample === null ||
      !responseExamples.size ||
      !responseExamples.has(selectedExample.mediaType || '') ||
      (responseExamples.has(selectedExample.mediaType || '') &&
        !(responseExamples.get(selectedExample.mediaType || '') as Map<string, ExtendedMediaTypeExample>).has(
          selectedExample.id || '',
        ))
    ) {
      setCode(providedCode);
      setFormattedLanguage(providedLanguage);
      return;
    }

    const example = (
      responseExamples.get(selectedExample.mediaType || '') as Map<string, ExtendedMediaTypeExample>
    ).get(selectedExample.id || '');
    setCode(example?.code || '');
    setFormattedLanguage(example?.mediaType || '');
  }, [responseExamples, providedCode, providedLanguage, selectedExample, setFormattedLanguage]);

  const [selectedStatus, selectedLabel, selectedSource, selectedMethod] = useMemo(() => {
    let status;
    let label;
    let source;
    let method;

    if (
      selectedExample?.id &&
      responseExamples.has(selectedExample.mediaType || '') &&
      (responseExamples.get(selectedExample.mediaType || '') as Map<string, ExtendedMediaTypeExample>).has(
        selectedExample.id,
      )
    ) {
      source = 'Example';
      [status, method] = selectedExample.id.split('_');
      const selected = (
        responseExamples.get(selectedExample.mediaType || '') as Map<string, ExtendedMediaTypeExample>
      ).get(selectedExample.id);
      if (selected && 'summary' in selected) {
        label = selected.summary;
      }
    } else if (har) {
      status = providedStatus;
      if (har?.log.entries[0].request?.headers) {
        const headers = har?.log.entries[0].request?.headers;
        const userAgent = headers.find(header => header.name.toLowerCase() === 'user-agent');

        source = userAgent?.value === 'ReadMe-API-Explorer' ? 'Try It' : 'Log';
      } else {
        source = 'Log';
      }
    }

    return [status, label, source, method];
  }, [responseExamples, har, providedStatus, selectedExample]);

  useEffect(() => {
    if (selectedExample) {
      const response = operation.getResponseByStatusCode(selectedExample.status as string);

      const exampleHeaders =
        typeof response === 'object' && response?.headers
          ? Object.keys(response.headers).map(header => {
              const retypedHeader = response.headers?.[header] as HeaderObject;
              const type = (retypedHeader?.schema as SchemaObject)?.type;
              return { name: header, value: retypedHeader?.description || '', type };
            })
          : null;

      return setResponseHeaders(exampleHeaders);
    }

    return setResponseHeaders(providedHeaders);
  }, [selectedExample, providedHeaders, operation]);

  const header = useMemo(
    () => (
      <Flex align="center" className={classes['APIResponse-header']} tag="header">
        <div>
          <Button
            key="callback-response-tab"
            className={`${classes['APIResponse-header-tab']} ${
              selectedExampleType === 'Response' ? classes['APIResponse-header-tab-active'] : ''
            } ${operation.getCallbackExamples().length ? '' : classes['APIResponse-header-tab-disabled']}`}
            disabled={Boolean(!operation.getCallbackExamples().length)}
            ghost
            kind="minimum"
            onClick={() => setSelectedExampleType('Response')}
            size="xs"
          >
            RESPONSE
          </Button>
          {Boolean(operation.getCallbackExamples().length) && (
            <Button
              key="callback-example-tab"
              className={`${classes['APIResponse-header-tab']} ${
                selectedExampleType === 'Callback' ? classes['APIResponse-header-tab-active'] : ''
              }`}
              ghost
              kind="minimum"
              onClick={() => setSelectedExampleType('Callback')}
              size="xs"
            >
              CALLBACK
            </Button>
          )}
        </div>
        {!!(hasProvidedData || Boolean(responseExamples.size)) && (
          <Dropdown
            className={classes['APIResponse-action']}
            clickInToClose
            justify="end"
            offset={[-1, 4]}
            sticky
            trigger="click"
          >
            <Button
              className={`APIResponse-action-button ${classes['APIResponse-action-button']}`}
              ghost
              kind="secondary"
              size="xs"
            >
              {selectedExample?.id || hasProvidedData ? (
                <>
                  <HTTPStatus iconOnly={true} status={selectedStatus} />
                  {selectedExampleType === 'Callback' && !!selectedMethod && (
                    <Method className="APIResponse-menu-method" fixedWidth type={selectedMethod.toLowerCase()} />
                  )}
                  <div className="APIResponse-menu-status">
                    {selectedStatus}
                    {!!selectedLabel && <>&nbsp;-&nbsp;{selectedLabel}</>}
                  </div>

                  <Badge kind="light">{selectedSource}</Badge>
                </>
              ) : (
                <span>Examples</span>
              )}
              <i className="icon-chevron-down APIResponse-action-icon" />
            </Button>
            <Menu className="APIResponse-menu">
              {Boolean(responseExamples.size) && (
                <>
                  {[...responseExamples].map(([mediaType, mediaTypeExamples]) => {
                    return (
                      <React.Fragment key={mediaType}>
                        <div className={`APIResponse-menu-header ${classes['APIResponse-header-button']}`}>
                          {mediaType}
                        </div>
                        {[...mediaTypeExamples].map(([key]) => {
                          // For normal responses the example keys here are a concatenated status
                          // and optional label, but on callbacks it's a label, method, and summary.
                          const [statusCode, ...label] = key.split('_');

                          // This information *should* always be in the label for callbacks but
                          // there's checks for it later anyways.
                          const [method, title] = selectedExampleType === 'Callback' ? label : [];

                          return (
                            <MenuItem
                              key={`${mediaType}-${key}`}
                              className="APIResponse-menu-item APIResponse-menu-example"
                              data-testid={`APIResponse-menu-item[${mediaType}][${statusCode}][${
                                label.length ? label.join(' ') : ''
                              }]`}
                              onClick={() => handleMenuItemClick(mediaType, statusCode, key)}
                            >
                              <div className="APIResponse-menu-item-wrapper">
                                {selectedExampleType === 'Response' ? (
                                  <div className="APIResponse-menu-status-wrapper">
                                    <HTTPStatus iconOnly={true} status={statusCode} />
                                    <div className="APIResponse-menu-status">
                                      {statusCode}
                                      {Boolean(label.length) && <>&nbsp;-&nbsp;{label.join(' ')}</>}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="APIResponse-menu-status-wrapper">
                                    {!!method && (
                                      <Method
                                        className="APIResponse-menu-method"
                                        fixedWidth
                                        type={label[0].toLowerCase() as HTTPMethod}
                                      />
                                    )}
                                    <div className="APIResponse-menu-identifier">
                                      {/* status code is the callback identifier here */}
                                      {`${statusCode} ${title ? ` - ${title}` : ''}`}
                                    </div>
                                  </div>
                                )}
                                {selectedExample?.id === key && <i className="icon-check1 APIResponse-menu-check" />}
                              </div>
                            </MenuItem>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                  <MenuDivider className="APIResponse-menu-divider" />
                </>
              )}
              <MenuItem
                className="APIResponse-menu-item"
                color="red"
                disabled={!selectedExample && !hasProvidedData}
                onClick={() => handleMenuItemClick(null)}
              >
                {selectedExample ? 'Clear Example' : 'Clear Response'}
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </Flex>
    ),
    [
      selectedExampleType,
      operation,
      hasProvidedData,
      responseExamples,
      selectedExample,
      selectedStatus,
      selectedMethod,
      selectedLabel,
      selectedSource,
      handleMenuItemClick,
    ],
  );

  const footer = useMemo(() => {
    return (
      <footer className={classes['APIResponse-footer']}>
        <CopyToClipboard className={classes['APIResponse-copyButton']} shift="end" text={formattedCode || ''} />
        {!!responseHeaders && (
          <>
            <Button
              className="APIResponse-action-button"
              ghost
              kind="secondary"
              onClick={toggleResponseHeadersModal}
              size="sm"
            >
              <span>Headers</span>
              <i className="icon-maximize-2 APIResponse-action-icon APIResponse-action-icon_large" />
            </Button>

            <Modal ref={responseHeadersModal} scrollable size="lg" target="#response-headers-modal-root" verticalCenter>
              <APIResponseHeadersModal
                headers={responseHeaders}
                onToggle={toggleResponseHeadersModal}
                requestData={requestData || undefined}
                requestHeaders={requestHeaders || undefined}
              />
            </Modal>
          </>
        )}
      </footer>
    );
  }, [formattedCode, responseHeaders, toggleResponseHeadersModal, requestHeaders, requestData]);

  // Don't render anything if Try It is disabled and there aren't any examples available.
  if (!requestsEnabled && !isExamplesPopulated) return null;

  if (formattedCode) {
    return (
      <CodeSnippet
        className={`${classes.APIResponse} ${classes.APIResponse_asCodeSnippet} rm-PlaygroundResponse`}
        code={formattedCode}
        footer={footer}
        header={header}
        language={language}
        options={options}
      />
    );
  }

  return (
    <section className={`${classes.APIResponse} rm-PlaygroundResponse`}>
      {header}
      <Flex align="center" className={classes['APIResponse-empty']} gap="md" layout="col">
        <div>
          {selectedExampleType === 'Response' && !!requestsEnabled && (
            <>
              Click <code className={classes['APIResponse-empty-tryit']}>Try It!</code> to start a request and see the
              response here!
            </>
          )}
          {!!isExamplesPopulated &&
            `${selectedExampleType === 'Response' && requestsEnabled ? ' Or c' : 'C'}hoose an example:`}
        </div>
        {Boolean(responseExamples.size) && (
          <Flex align="stretch" className={classes['APIResponse-empty-examples']} gap="sm" layout="col">
            {[...responseExamples].map(([mediaType, mediaTypeExamples]) => {
              return (
                <Flex
                  key={`examples-${selectedExampleType}-${mediaType}`}
                  align="center"
                  className={classes['APIResponse-empty-examples-list']}
                  gap="xs"
                  layout="col"
                >
                  <div>{mediaType}</div>
                  <Flex
                    className={classes['APIResponse-empty-examples-overflow']}
                    gap="4px"
                    justify="center"
                    wrap="wrap"
                  >
                    {[...mediaTypeExamples].map(([key]) => {
                      let statusCode: string | undefined;
                      let label: string[] = [];
                      let identifier: string | undefined;
                      let method: string | undefined;
                      let summary: string | undefined;
                      if (selectedExampleType === 'Response') {
                        [statusCode, ...label] = key.split('_');
                      } else {
                        [identifier, method, summary] = key.split('_');
                      }

                      return (
                        <button
                          key={`${mediaType}-${key}`}
                          className={
                            selectedExampleType === 'Response'
                              ? classes['APIResponse-empty-button']
                              : classes['APIResponse-empty-callback']
                          }
                          onClick={() => toggleSelectedExample(mediaType, statusCode, key)}
                        >
                          {selectedExampleType === 'Response' ? (
                            <>
                              <HTTPStatus iconOnly={true} status={statusCode} />
                              <span className="APIResponse-menu-status">
                                {statusCode}
                                {Boolean(label.length) && <>&nbsp;-&nbsp;{label.join(' ')}</>}
                              </span>
                            </>
                          ) : (
                            <span className="APIResponse-menu-callbacks">
                              <div className="APIResponse-menu-callbacks-header">
                                <div className="APIResponse-menu-callbacks-header-name">{identifier}</div>
                                {!!method && (
                                  <Method
                                    className="APIResponse-menu-method"
                                    fixedWidth
                                    type={method.toLowerCase() as HTTPMethod}
                                  />
                                )}
                              </div>
                              <div className="APIResponse-menu-callbacks-example">{summary || ''}</div>
                            </span>
                          )}
                        </button>
                      );
                    })}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        )}
      </Flex>
    </section>
  );
};

export default APIResponse;
