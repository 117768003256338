/* eslint-disable camelcase */
import { getStatusCode } from '@readme/http-status-codes';
import { isValidUrl, type $TSFixMe } from '@readme/iso';
import debugPackage from 'debug';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { ProjectContext, VersionContext, type ProjectContextValue, type VersionContextValue } from '@core/context';
import useAuthStorage from '@core/hooks/useAuthStorage';
import { useReferenceStore } from '@core/store';
import { completedStatuses, oauthStateSplitToken } from '@core/store/Reference/Auth/oauth-types';

const debug = debugPackage('readme:explorer:oauth');

/**
 * Custom hook that handles the OAuth flow for a given security scheme.
 * See the README for more details.
 */
export default function useOAuthFlow(
  input:
    | {
        /**
         * The identifying key for the security scheme
         */
        securitySchemeKey: string;

        /**
         * The source from which the hook is being invoked. If `original`, the flow is being initiated by the original window
         * (i.e., the authentication component used in our API explorer)
         */
        source: 'original';
      }
    | {
        /**
         * The identifying key for the security scheme
         */
        securitySchemeKey: string;

        /**
         * The source from which the hook is being invoked. If `redirect`, the flow is being initiated by the redirect window
         * (i.e., the `/oauth2-redirect` route that is used for the redirect on authorization code flows)
         */
        source: 'redirect';

        /**
         * The state query parameter from the OAuth redirect URL
         */
        state: string | null;
      },
) {
  const { securitySchemeKey, source } = input;
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { is_stable, version } = useContext(VersionContext) as VersionContextValue;
  const fullBaseUrl = `${project.fullBaseUrl}${is_stable ? '' : `/v${version}`}`.replace(/\/$/, '');

  const [
    /**
     * The result that will be displayed to the user in the authorization popup window
     */
    authCodeWindowResult,
    setAuthCodeWindowResult,
  ] = useState('');
  const [
    /**
     * The window object for the authorization popup window
     */
    authorizationWindow,
    setAuthorizationWindow,
  ] = useState<Window | null>(null);
  const [
    /**
     * Flag to indicate if the data is present for making the token request. Used to prevent race conditions.
     */
    isDataPresentForTokenRequest,
    setIsDataPresentForTokenRequest,
  ] = useState(false);
  const selectedFlow = useReferenceStore(s => s.auth.oauth.schemes[securitySchemeKey]?.draftState.selectedFlow);

  const [
    isReady,
    clientId,
    clientSecret,
    fullServerUrl,
    proxyEnabled,
    rawAuthorizationUrl,
    rawTokenUrl,
    redirectUri,
    scopeSeparator,
    selectedScopes,
    state,
    status,
    useInsecureClientAuthentication,
    updateOAuthStatus,
  ] = useReferenceStore(s => [
    s.isReady,
    s.auth.oauth.schemes[securitySchemeKey]?.draftState.clientId,
    s.auth.oauth.schemes[securitySchemeKey]?.draftState.clientSecret,
    s.form.fullServerUrl,
    s.auth.oauth.schemes[securitySchemeKey]?.proxyEnabled,
    selectedFlow === 'authorizationCode'
      ? s.auth.oauth.schemes[securitySchemeKey]?.flows?.[selectedFlow]?.authorizationUrl
      : undefined,
    s.auth.oauth.schemes[securitySchemeKey]?.flows?.[selectedFlow]?.tokenUrl,
    s.auth.oauth.schemes[securitySchemeKey]?.draftState.redirectUri,
    s.auth.oauth.schemes[securitySchemeKey]?.scopeSeparator,
    s.auth.oauth.schemes[securitySchemeKey]?.draftState.selectedScopes || [],
    s.auth.oauth.schemes[securitySchemeKey]?.draftState.state || '',
    s.auth.oauth.schemes[securitySchemeKey]?.draftState.status,
    s.auth.oauth.schemes[securitySchemeKey]?.useInsecureClientAuthentication,
    s.auth.updateOAuthStatus,
  ]);

  const { getStoredAuth } = useAuthStorage(() => {
    const newData = getStoredAuth()?.oauth?.schemes?.[securitySchemeKey]?.draftState;
    if (
      ((source === 'original' && (status === 'pending' || status === 'received')) ||
        (source === 'redirect' && isDataPresentForTokenRequest)) &&
      newData
    ) {
      debug('received new local storage data', newData);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setLocalStorageData(newData);
    }
  });

  const [localStorageData, setLocalStorageData] = useState(
    getStoredAuth()?.oauth?.schemes?.[securitySchemeKey]?.draftState,
  );

  // OpenAPI 3.0 allows for relative URLs in these fields.
  // If they're complete URLs, pass them through.
  // Otherwise, tack on current server URL.
  // See: https://swagger.io/docs/specification/authentication/oauth2/#relative-url
  const [authorizationUrl, tokenUrl] = useMemo(() => {
    const fullUrls = [rawAuthorizationUrl, rawTokenUrl].map(url =>
      url && !isValidUrl(url) ? `${fullServerUrl}${url}` : url,
    );

    debug('regenerating authorization and token URLs', {
      rawAuthorizationUrl,
      rawTokenUrl,
      fullServerUrl,
      fullUrls,
    });

    return fullUrls;
  }, [fullServerUrl, rawAuthorizationUrl, rawTokenUrl]);

  /**
   * Sets the failure state in the `ReferenceStore` and updates the auth code window result
   */
  const setFail = useCallback(
    (stateInput: string | null, error: { error: string; error_description?: string }) => {
      debug('setting failure state', { stateInput, error });
      updateOAuthStatus(securitySchemeKey, { ...error, state: stateInput || '', status: 'failure' });
      setAuthCodeWindowResult('Sorry, something went wrong. Please close this window and try again.');
    },
    [securitySchemeKey, updateOAuthStatus],
  );

  /**
   * Handles the client id/secret form submission
   */
  const submitClientCredentials = useCallback(() => {
    const newRedirectUri = `${fullBaseUrl}/oauth2-redirect`;
    const newState = `${securitySchemeKey}${oauthStateSplitToken}${uuid()}`;
    debug('submitting client form', { newState, newRedirectUri });
    updateOAuthStatus(securitySchemeKey, {
      redirectUri: newRedirectUri,
      state: newState,
      status: 'pending',
    });
  }, [fullBaseUrl, securitySchemeKey, updateOAuthStatus]);

  /**
   * Fetches the token from the token URL and updates the `ReferenceStore` with the response
   */
  const fetchToken = useCallback(
    async (opts: { code: string; type: 'authorizationCode' } | { type: 'clientCredentials' }) => {
      setIsDataPresentForTokenRequest(true);
      try {
        // fetchToken is always called when tokenUrl is present, so we can safely use the non-null assertion operator
        const proxiedTokenUrl = proxyEnabled ? `https://try.readme.io/${tokenUrl}` : tokenUrl!;
        const headers = new Headers({ 'content-type': 'application/x-www-form-urlencoded' });
        const payload = new URLSearchParams();
        let finalStatus: typeof status;

        switch (opts.type) {
          case 'authorizationCode':
            payload.set('grant_type', 'authorization_code');
            payload.set('code', opts.code);
            payload.set('redirect_uri', redirectUri);
            finalStatus = 'received';
            break;
          case 'clientCredentials':
          default:
            payload.set('grant_type', 'client_credentials');
            if (selectedScopes.length) {
              payload.set('scope', selectedScopes.join(scopeSeparator));
            }
            finalStatus = 'done';
            break;
        }

        if (useInsecureClientAuthentication) {
          payload.set('client_id', clientId);
          payload.set('client_secret', clientSecret);
        } else {
          headers.set('authorization', `Basic ${btoa(`${clientId}:${clientSecret}`)}`);
        }

        debug('making token request', {
          proxiedTokenUrl,
          headers,
          payload: payload.toString(),
        });

        const response = await fetch(proxiedTokenUrl, {
          method: 'POST',
          headers,
          body: payload.toString(),
        });

        debug(`received status: ${response.status}`);
        const text = await response.text();
        const responseStatus = getStatusCode(response.status);
        debug(`response text: ${text}`);
        let body: $TSFixMe = {};
        try {
          body = JSON.parse(text);
        } catch (e) {
          debug('failed to parse response body', e);
          body = { error_description: `Failed to parse response body: ${e.message}` };
        }
        debug('parsed response body', { body });
        if (response.ok && body.access_token) {
          updateOAuthStatus(securitySchemeKey, { response: body, state, status: finalStatus });
        } else {
          setFail('state' in input ? input.state : state, {
            error: body.error
              ? `${responseStatus.code} ${responseStatus.message}: Received the following error when attempting to request a token: ${body.error}`
              : `${responseStatus.code} ${responseStatus.message}: There was an issue requesting the token. Please verify your client credentials and try again.`,
            error_description: body.error_description || `raw response: ${text}`,
          });
        }
      } catch (e) {
        setFail('state' in input ? input.state : state, {
          error: 'There was an issue requesting the token. Please verify your client credentials and try again.',
          error_description: e.message,
        });
      }
    },
    [
      clientId,
      clientSecret,
      input,
      proxyEnabled,
      redirectUri,
      scopeSeparator,
      securitySchemeKey,
      selectedScopes,
      setFail,
      state,
      tokenUrl,
      updateOAuthStatus,
      useInsecureClientAuthentication,
    ],
  );

  /**
   * Ensures all the data is present before making the token request
   */
  const makeTokenRequest = useCallback(
    async (code?: string | null) => {
      if (
        isReady &&
        !isDataPresentForTokenRequest &&
        clientId &&
        clientSecret &&
        state &&
        status === 'pending' &&
        tokenUrl
      ) {
        if (selectedFlow === 'authorizationCode') {
          debug('constructing token request for authorization code flow', { code, input, state });
          if (input.source !== 'redirect' || !input.state || input.state !== state) {
            setFail('', { error: 'Sorry, something went wrong. Please try again.' });
          } else if (!code) {
            setFail(input.state, {
              error: 'There was an issue requesting the token. Please verify your client credentials and try again.',
            });
          } else {
            await fetchToken({ code, type: 'authorizationCode' });
          }
        } else if (selectedFlow === 'clientCredentials') {
          debug('constructing token request for client credentials flow', { state });
          await fetchToken({ type: 'clientCredentials' });
        }
      }
    },
    [
      clientId,
      clientSecret,
      fetchToken,
      input,
      isDataPresentForTokenRequest,
      isReady,
      selectedFlow,
      setFail,
      state,
      status,
      tokenUrl,
    ],
  );

  // once all the data is available in `ReferenceStore`, iniitate the token request
  // (either by opening the authorization window or making the token request directly)
  useEffect(() => {
    if (
      selectedFlow === 'authorizationCode' &&
      !authorizationWindow &&
      clientId &&
      state &&
      redirectUri &&
      source === 'original' &&
      status === 'pending'
    ) {
      // https://aaronparecki.com/oauth-2-simplified/#web-server-apps
      const searchParams = new URLSearchParams({
        response_type: 'code',
        client_id: clientId,
        redirect_uri: redirectUri,
        state,
      });

      if (selectedScopes.length) {
        searchParams.set('scope', selectedScopes.join(scopeSeparator));
      }

      const url = `${authorizationUrl}?${searchParams.toString()}`;

      debug('attempting to open authorization window', { url });

      const currentAuthWindow = window.open(url, 'authorizationWindow', 'popup');
      if (!currentAuthWindow) {
        setFail(state, {
          error: 'Sorry, the authorization popup window was blocked. Please try again.',
        });
      }
      setAuthorizationWindow(currentAuthWindow);
    } else if (selectedFlow === 'clientCredentials' && clientId && source === 'original' && status === 'pending') {
      debug('invoking makeTokenRequest for client credentials flow');
      makeTokenRequest();
    }
  }, [
    authorizationUrl,
    authorizationWindow,
    clientId,
    makeTokenRequest,
    redirectUri,
    scopeSeparator,
    selectedFlow,
    selectedScopes,
    setFail,
    source,
    state,
    status,
  ]);

  // handles the updates to the `ReferenceStore` based on the local storage data
  useEffect(() => {
    // Once the authorization popup window has updated local storage with the result, it will update local storage.
    // In the original window, we wait for these local storage updates and update our `ReferenceStore` accordingly.
    // We also clean up a few things like closing the authorization window and resetting isDataPresentForTokenRequest.
    if (source === 'original') {
      if (localStorageData?.state === state) {
        let updatedStatus: Parameters<typeof updateOAuthStatus>[1] | undefined;
        // if the authorization window token request was successful, update the status to `done`
        if (localStorageData?.status === 'received' && status !== 'done') {
          updatedStatus = { state, status: 'done' };
        }
        // if the authorization window token request failed, surface the error
        else if (localStorageData?.status === 'failure' && status !== 'failure') {
          updatedStatus = { state, status: 'failure' };
        }

        debug('state matches local storage, updating store accordingly', {
          localStorageData,
          state,
          status,
          updatedStatus,
        });

        if (updatedStatus) {
          updateOAuthStatus(securitySchemeKey, {
            ...localStorageData,
            ...updatedStatus,
          });
        }
      }

      // if the status is one of the "completed" states,
      // clean up the authorization window and reset the data flag
      if (completedStatuses.includes(status)) {
        debug('cleaning up window and resetting data flag', {
          isDataPresentForTokenRequest,
          localStorageData,
          state,
          status,
        });

        if (isDataPresentForTokenRequest) {
          debug('resetting isDataPresentForTokenRequest');
          setIsDataPresentForTokenRequest(false);
        }

        if (authorizationWindow) {
          debug('closing authorization window');
          authorizationWindow.close();
          setAuthorizationWindow(null);
        }
      }
    }

    // if the original page received the updated data, we can inform the user that the authorization popup window can now be closed
    // (just in case we are not able to automatically close it due to browser restrictions)
    // this block also handles the edge case where the user navigates to the authorization page
    // directly with an invalid/missing state
    else if (source === 'redirect') {
      if (localStorageData?.status === 'done' && localStorageData?.state === state && state === input.state) {
        debug('redirect window can now be closed due to success state', { localStorageData, input, state });
        setAuthCodeWindowResult('You may now close this window.');
      } else if (localStorageData?.status !== 'pending' && (state !== input.state || !input.state)) {
        debug('redirect window can now be closed due to non-success state', { localStorageData, input, state });
        setAuthCodeWindowResult('Sorry, something went wrong. Please close this window and try again.');
      }
    }
  }, [
    authorizationWindow,
    input,
    isDataPresentForTokenRequest,
    localStorageData,
    securitySchemeKey,
    source,
    state,
    status,
    updateOAuthStatus,
  ]);

  // handles the polling for the authorization popup window to see if it was closed,
  // as well as the timeout in case the window is open for too long
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (source === 'original' && !!authorizationWindow) {
      debug('starting interval to check if authorization window was closed');
      let count = 0;
      interval = setInterval(() => {
        if (authorizationWindow?.closed && status === 'pending') {
          debug('authorization window was closed, updating status');
          setFail(state, {
            error: 'The authorization window was closed before authorization was completed. Please try again.',
          });
          clearInterval(interval);
        }

        count += 1;
        // if window is open for longer than 5 minutes, close it
        if (count > 6000) {
          debug('authorization window timed out, closing');
          setFail(state, { error: 'Sorry, your authorization request timed out.' });
          clearInterval(interval);
        }
      }, 50);
    }

    return () => clearInterval(interval);
  }, [authorizationWindow, setFail, source, state, status]);

  return { authCodeWindowResult, makeTokenRequest, submitClientCredentials };
}
